<template>
    <div class="read-more-block">
        <div
            ref="content-wrap"
            :class="classes"
            :style="heightReadMoreBlock"
            class="read-more-block__content"
        >
            <slot ref="content" />
        </div>

        <a
            v-if="showMoreButton && showLessButton"
            class="read-more-block__btn"
            @click.prevent="handleClickReadMore"
        >
            {{ textButton }}
        </a>
    </div>
</template>

<script>
export default {
    name: "ReadMoreBlock",

    props: {
        startHeight: {
            type: Number,
            default: 10,
        },

        showLessButton: {
            type: Boolean,
            default: true,
        },
    },

    setup(_, { slots }) {
        return { slots };
    },

    data() {
        return {
            isOpen: false,
            heightContent: this.startHeight,
        };
    },

    computed: {
        classes() {
            return [ {
                "read-more-block__content--is-closed": !this.isOpen,
            } ];
        },

        textButton() {
            return this.isOpen ? this.$t("COMMON.READ_LESS") : this.$t("COMMON.READ_MORE");
        },

        showMoreButton() {
            return this.slots.default;
        },

        heightReadMoreBlock() {
            if (this.heightContent && !this.isOpen) {
                return { height: `${ this.heightContent }px` };
            }
        },
    },

    methods: {
        handleClickReadMore() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.read-more-block {
    &__btn {
        display: block;
        margin: 1rem auto 0;

        @include media(M) {
            text-align: center;
        }
    }

    &__content {
        position: relative;
        overflow: hidden;
        transition: all .5s;

        &--is-closed {

            &:after {
                display: block;
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0), var(--color-layer-body));
            }
        }
    }
}
</style>
